function Setting_rchat_api(props) {
  const { lang } = props;

  const [selectedAPI, setSelectedAPI] = React.useState("");
  const [tempSelectedAPI, setTempSelectedAPI] = React.useState("");
  const [info, setInfo] = React.useState({});
  const [tempInfo, setTempInfo] = React.useState({});
  const [webhookUrl, setWebhookUrl] = React.useState("");
  const [apiConnectSource, setApiConnectSource] = React.useState("");
  const [webhookStatus, setWebhookStatus] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [apiWarning, setApiWarning] = React.useState(false);
  const [editApi, setEditApi] = React.useState(false);
  const [rchatBot, setRchatBot] = React.useState(false);
  const [apiConnect, setApiConnect] = React.useState(false);
  const [businessHoursStatus, setBusinessHoursStatus] = React.useState(false);
  const [chatbotBusinessHoursStatus, setChatbotBusinessHoursStatus] =
    React.useState(false);
  const [tempChatbotBusinessHoursStatus, setTempChatbotBusinessHoursStatus] =
    React.useState(false);
  const [
    tempChatbotOutsideBusinessHoursStatus,
    setTempChatbotOutsideBusinessHoursStatus,
  ] = React.useState(false);
  const [
    chatbotOutsideBusinessHoursStatus,
    setChatbotOutsideBusinessHoursStatus,
  ] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    checkRchatbot();
  }, []);

  const checkRchatbot = () => {
    axios({
      method: "post",
      url: backend + "business/info/update",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": props.business_data.access,
        Authorization: "Bearer " + props.business_data.token,
      },
      data: JSON.stringify({
        action: ["business_app_changed"],
      }),
    }).then((res) => {
      if (res.data.result === 0) {
        getChatdayApiInfo();
      }
    });
  };

  const getChatdayApiInfo = () => {
    axios({
      method: "post",
      url:
        backend +
        "setting/" +
        props.business_data.uid +
        "/" +
        props.business_data.app_id +
        "/api",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": props.business_data.access,
        Authorization: "Bearer " + props.business_data.token,
      },
      data: JSON.stringify({
        channelId: props.business_data.channelId,
      }),
    }).then((res) => {
      if (res.data.result === 0) {
        setInfo(res.data.data);
        setTempInfo(res.data.data);
        setApiConnectSource(res.data.data.chat_api_active_source);
        setSelectedAPI(
          res.data.data.chatday_api_active_status
            ? res.data.data.chat_api_active_source === "r_chat_bot"
              ? "r_chat_bot"
              : "r_chat_api"
            : "off"
        );
        setTempSelectedAPI(
          res.data.data.chatday_api_active_status
            ? res.data.data.chat_api_active_source === "r_chat_bot"
              ? "r_chat_bot"
              : "r_chat_api"
            : "off"
        );
        setApiConnect(Boolean(res.data.data.chatday_api_active_status));
        setWebhookStatus(Boolean(res.data.data.chatday_api_active_status));
        setWebhookUrl(res.data && res.data.data && res.data.data.webhook);
        setRchatBot(Boolean(res.data.data.rchatbot_active_status));
        setBusinessHoursStatus(Boolean(res.data.data.business_hours_status));
        if (props.business_data.settingInfo.business_hours_status) {
          setChatbotBusinessHoursStatus(
            res.data.data.reception_default_status
          );
          setTempChatbotBusinessHoursStatus(
            res.data.data.reception_default_status
          );
          setChatbotOutsideBusinessHoursStatus(
            res.data.data.business_hours_chat_bot
          );
          setTempChatbotOutsideBusinessHoursStatus(
            res.data.data.business_hours_chat_bot
          );
        }
        setIsLoading(false);
      } else {
        setSelectedAPI("off");
        setIsLoading(false);
      }
    });
  };

  const callAccessToken = () => {
    axios({
      method: "post",
      url:
        backend +
        "setting/" +
        props.business_data.uid +
        "/" +
        props.business_data.app_id +
        "/api/regenerate-token",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": props.business_data.access,
        Authorization: "Bearer " + props.business_data.token,
      },
      data: JSON.stringify({
        channelId: props.business_data.channelId,
      }),
    }).then((res) => {
      if (res.data.result === 0) {
        setInfo({
          ...info,
          chatday_access_token: res.data.data.chatday_access_token,
          chatday_signature_token: res.data.data.chatday_signature_token,
        });
        setTempInfo({
          ...tempInfo,
          chatday_access_token: res.data.data.chatday_access_token,
          chatday_signature_token: res.data.data.chatday_signature_token,
        });
        props.toast.success(lang.__chatday_api_reset_token_successfully);
      } else {
        props.toast.error(lang.__connection_lost_please_try_again);
      }
    });
  };

  const toggleWebhookStatus = (StatusWebhook) => {
    setIsUpdating(true);
    axios({
      method: "post",
      url:
        backend +
        "setting/" +
        props.business_data.uid +
        "/" +
        props.business_data.app_id +
        "/api/webhook",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": props.business_data.access,
        Authorization: "Bearer " + props.business_data.token,
      },
      data: JSON.stringify({
        webhook: {
          status: StatusWebhook,
          url: webhookUrl,
          api_source: selectedAPI,
        },
        channelId: props.business_data.channelId,
        chatbotHours: {
          businessHours: chatbotBusinessHoursStatus,
          outsideBusinessHours: chatbotOutsideBusinessHoursStatus,
        },
      }),
    }).then((res) => {
      setIsUpdating(false);
      if (res.data.result === 0) {
        setApiConnect(
          webhookUrl === "" && selectedAPI === "r_chat_api"
            ? false
            : StatusWebhook
        );
        setTempInfo({
          ...tempInfo,
          webhook: { url: webhookUrl, status: StatusWebhook },
        });
        setTempSelectedAPI(selectedAPI);
        setApiConnectSource(selectedAPI);
        setTempChatbotBusinessHoursStatus(chatbotBusinessHoursStatus);
        setTempChatbotOutsideBusinessHoursStatus(
          chatbotOutsideBusinessHoursStatus
        );
        props.toast.success(lang.__chatday_api_save_successfully);
        if (res && res.data && res.data.data) {
          setInfo(
            Object.assign(info, {
              chatday_access_token: res.data.data.chatday_access_token,
              chatday_signature_token: res.data.data.chatday_signature_token,
            })
          );
        }
        props.setWebhook(webhookUrl, StatusWebhook, selectedAPI);
      } else {
        setIsUpdating(false);
        setApiWarning(true);
        setEditApi(true);
        props.toast.error(lang.__chatday_api_webhook_verify_fail);
      }
    });
  };

  const genAccessToken = () => {
    props
      .confirmDialog(
        "__chatday_api_reset_generate_new_token",
        "__chatday_api_reset_generate_new_token_description",
        {
          btn_class: "-red",
          submit_text: "__reset",
        }
      )
      .then(() => {
        callAccessToken();
      });
  };

  const handleChangeSelect = (e) => {
    setWebhookStatus(true);
    setSelectedAPI(e.target.value);
    if (e.target.value === "off") {
      setWebhookStatus(false);
    }
  };

  const onClipboard = (code) => {
    navigator.clipboard.writeText(code);
    props.toast.success(lang.__chatday_link_copy_success);
  };

  const toggleEditWebhook = () => {
    if (editApi) {
      toggleWebhookStatus(webhookStatus);
    }
    setEditApi(!editApi);
  };

  const cancel = () => {
    setInfo(tempInfo);
    setWebhookUrl(tempInfo.webhook);
    setWebhookStatus(tempInfo.chatday_api_active_status);
    setApiConnect(tempInfo.chatday_api_active_status);
    setSelectedAPI(tempSelectedAPI);
    setChatbotBusinessHoursStatus(tempChatbotBusinessHoursStatus);
    setChatbotOutsideBusinessHoursStatus(tempChatbotOutsideBusinessHoursStatus);
    setEditApi(false);
    setApiWarning(false);
  };

  return (
    <div style={{ height: "100%" }}>
      {isLoading ? (
        <div class="sp-placeholder">
          <div class="sp-text-help">
            <i class="icon icon-circle-notch animate-spin"></i>{" "}
            <span>{lang.__loading}</span>
          </div>
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          <div
            class="sp-row"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70px",
              padding: "0 20px",
              borderBottom: "1px solid rgb(225, 231, 242)",
            }}
          >
            <div class="sp-col">
              <span class="sp-label">{props.lang.__setting_chatday_api}</span>
              <ReactTooltip text={props.lang.__manual_chatbot}>
                <a
                href="https://help.readyplanet.com/rchat/chatbot"
                target="_blank"
              >
                <i
                  class="icon-help-circled sp-link"
                  tooltip-placement="right"
                ></i>
              </a>
              </ReactTooltip>
            </div>
          </div>
          <div
            style={{
              height: "calc(100% - 70px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Body style={{ "flex-grow": "1" }}>
              <div
                style={{
                  padding: "20px 0",
                  borderBottom: "1px solid rgb(225, 231, 242)",
                }}
              >
                <div class="sp-section sp-row" style={{ marginTop: 16 }}>
                  <div class="sp-col">
                    <span class="sp-label">{lang.__setting_type_of}</span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 26,
                    marginBottom: 10,
                  }}
                >
                  <div>
                    <div class="sp-checkbox">
                      <input
                        class="sp-input"
                        type="radio"
                        value="off"
                        checked={selectedAPI === "off"}
                        onClick={handleChangeSelect}
                      />
                      <span>{lang.__off_chatbot}</span>
                    </div>
                  </div>
                  {rchatBot ? (
                    <div class="sp-checkbox">
                      <input
                        class="sp-input"
                        type="radio"
                        value="r_chat_bot"
                        checked={selectedAPI === "r_chat_bot"}
                        onClick={handleChangeSelect}
                      />
                      <span>{lang.__r_chatbot}</span>
                    </div>
                  ) : null}
                  <div class="sp-checkbox">
                    <input
                      class="sp-input"
                      type="radio"
                      value="r_chat_api"
                      checked={selectedAPI === "r_chat_api"}
                      onClick={handleChangeSelect}
                    />
                    <span>{lang.__chatday_api}</span>
                  </div>
                </div>
                {selectedAPI === "r_chat_api" ? (
                  <div>
                    <div class="sp-section">
                      <p class="sp-label no-margin">
                        {lang.__chatday_api_access_token}
                      </p>
                      <p class="sp-text-help">
                        {lang.__chatday_api_access_token_description}
                      </p>
                      <div class="sp-input-group">
                        <span class="sp-input sp-text -ellipsis" disabled>
                          {info.chatday_access_token}
                        </span>
                        <button
                          class="sp-btn icon-docs add-on"
                          onClick={() => onClipboard(info.chatday_access_token)}
                        ></button>
                        <button
                          class="sp-btn icon-cw add-on"
                          onClick={() => genAccessToken()}
                        ></button>
                      </div>
                    </div>
                    <div class="sp-section">
                      <p class="sp-label no-margin">
                        {lang.__chatday_api_signature_token}
                      </p>
                      <p class="sp-text-help">
                        {lang.__chatday_api_signature_token_description}
                      </p>
                      <div class="sp-input-group">
                        <input
                          class="sp-input sp-text -ellipsis"
                          readOnly
                          disabled
                          placeholder={
                            lang.__chatday_api_signature_token_waiting_for_registration
                          }
                          value={info.chatday_signature_token}
                        />
                        {info.chatday_signature_token ? (
                          <button
                            class="sp-btn icon-docs add-on"
                            onClick={() =>
                              onClipboard(info.chatday_signature_token)
                            }
                          ></button>
                        ) : null}
                      </div>
                    </div>
                    <div className="sp-section">
                      <p className="sp-label no-margin">
                        {lang.__chatday_api_webhook_url}
                      </p>
                      <p className="sp-text-help">
                        {lang.__chatday_api_webhook_url_description}
                      </p>
                      <div
                        class="sp-input-group"
                        style={{
                          border: `${apiWarning ? "1px solid red" : ""}`,
                        }}
                      >
                        <input
                          class="sp-input sp-text -ellipsis"
                          type="text"
                          readOnly={
                            !editApi || info.chatday_signature_token === ""
                          }
                          value={webhookUrl}
                          onChange={(e) => {
                            setWebhookUrl(e.target.value);
                            setApiWarning(false);
                          }}
                        />
                        {apiWarning ? (
                          <button
                            class="sp-btn add-on icon-attention-circled warning-alert"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={lang.__chatday_api_webhook_verify_fail}
                          ></button>
                        ) : (
                          <button
                            className={
                              editApi
                                ? "sp-btn icon-ok add-on"
                                : "sp-btn icon-pencil add-on"
                            }
                            style={{
                              cursor: info.chatday_signature_token
                                ? "pointer"
                                : "not-allowed",
                            }}
                            onClick={() => toggleEditWebhook()}
                            disabled={!info.chatday_signature_token}
                          ></button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {selectedAPI !== "off" &&
              businessHoursStatus ? (
                <div
                  style={{
                    padding: "20px 0",
                    borderBottom: "1px solid rgb(225, 231, 242)",
                  }}
                >
                  <div class="sp-section sp-row" style={{ marginTop: 16 }}>
                    <div class="sp-col">
                      <span class="sp-label">
                        {lang.__business_chatbot_working}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: 26,
                    }}
                  >
                    <div
                      class="sp-row"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50px",
                        padding: "0 20px",
                      }}
                    >
                      <div class="sp-col">
                        <span>{props.lang.__business_hours}</span>
                      </div>
                      <Toggle defaultStatus={chatbotBusinessHoursStatus}>
                        <label>
                          <input
                            type="checkbox"
                            onChange={() =>
                              setChatbotBusinessHoursStatus(
                                !chatbotBusinessHoursStatus
                              )
                            }
                          />
                          <div class="toggle-button cursor-pointer">
                            <span></span>
                          </div>
                        </label>
                      </Toggle>
                    </div>
                    <div
                      class="sp-row"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50px",
                        padding: "0 20px",
                      }}
                    >
                      <div class="sp-col">
                        <span>{props.lang.__business_hours_outside}</span>
                      </div>
                      <Toggle defaultStatus={chatbotOutsideBusinessHoursStatus}>
                        <label>
                          <input
                            type="checkbox"
                            onChange={() =>
                              setChatbotOutsideBusinessHoursStatus(
                                !chatbotOutsideBusinessHoursStatus
                              )
                            }
                          />
                          <div class="toggle-button cursor-pointer">
                            <span></span>
                          </div>
                        </label>
                      </Toggle>
                    </div>
                  </div>
                </div>
              ) : null}
            </Body>
            <Footer>
              <button class="sp-btn -link" onClick={() => cancel()}>
                {lang.__cancel}
              </button>
              <button
                class="sp-btn -blue"
                onClick={() => toggleWebhookStatus(webhookStatus)}
                disabled={isUpdating || 
                (selectedAPI === 'r_chat_api' && (!(webhookUrl) || !(info.chatday_signature_token))) ||
                (!apiConnect && selectedAPI === 'off')}
              >
                {isUpdating && <i class="icon-circle-notch animate-spin"></i>}
                <span>{lang.__save}</span>
              </button>
            </Footer>
          </div>
        </div>
      )}
    </div>
  );
}

const Body = window.styled.div`
    overflow: auto;
    scroll-behavior: smooth;
    padding: 0 14%;
    @media (max-width: 767px) {
    padding: 0 2%;
}
`;

const Footer = window.styled.div`
display: flex; 
flex-direction: row;
justify-content: flex-end;
border-top: 1px solid #E1E7F2;
padding: 16px;
flex-shrink: 0;
min-height: 0;
`;
